import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import axiosConfig from "../utils/axiosConfig";
import {diffYears, notify} from "../utils/utils";
import Loading from "../partial/Loading"; // Optional Theme applied to the Data Grid
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import "datatables.net-dt/css/dataTables.dataTables.css";
import YearlyReport from "./YearlyReport";
import MonthlyReport from "./MonthlyReport";

DataTable.use(DT);

const Reports = ({agency}) => {
    const auth = useAuth();
    const currentYear = new Date().getFullYear();

    const [isLoading, setIsLoading] = useState(false);
    const [from, setFrom] = useState(`${currentYear}-01-01`);
    const [to, setTo] = useState(`${currentYear}-12-31`);
    const [viewType, setViewType] = useState("year");
    const [agencyNo, setAgencyNo] = useState(auth.user.Kundennummer);
    const [report, setReport] = useState([]);

    const load = async () => {
        let url = `/protected/agency/${agencyNo}/provisions/${from}/${to}`;
        if (viewType === "month") {
            url = `/protected/agency/${agencyNo}/provisions-month/${from}/${to}`;
        }
        return await axiosConfig.get(url, {
            "headers": {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
            },
        });
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (agencyNo === 0) {
            notify("Bitte ein Agentur auswählen");
            return
        }

        if (diffYears(from, to) > 1) {
            notify(`Bitte Maximal ein jahr Zeitraum auswählen z.B. 01.01.${currentYear} - 31.12.${currentYear}`);
            return
        }

        setIsLoading(true);
        load().then((result) => {
            setReport(result.data)
            setIsLoading(false);
        }).catch((e) => {
            console.log(e)
            notify(e.message)
            setIsLoading(false);
        })
    };

    useEffect(() => {
    }, [])

    return (
        <div className="tab-pane fade " id="report" role="tabpanel"
             aria-labelledby="report-tab">

            <div className="mb-5">
                <h1>Report Forecast Provisionen</h1>
                <p><i className="fa fa-info-circle"></i> Das Zusammenstellen der Daten ist
                    sehr
                    rechenintensiv. Bitte haben Sie etwas Geduld, bis Ihnen der Report
                    angezeigt
                    wird.</p>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="date" className="form-control"
                                       name="from"
                                       value={from}
                                       onChange={(e) => setFrom(e.target.value)}
                                       id="from" placeholder="Zeitraum von"

                                />
                                <label htmlFor="from" className="form-label">Zeitraum
                                    von:</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="date" className="form-control"
                                       name="to"
                                       value={to}
                                       onChange={(e) => setTo(e.target.value)}
                                       id="to" placeholder="Zeitraum bis"
                                />
                                <label htmlFor="to" className="form-label">Zeitraum
                                    bis</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <select className="form-select" aria-label="Default select example"
                                        onChange={(e) => setAgencyNo(e.target.value)}>
                                    <option
                                        value={agency?.Agenturnummer}>{agency?.Name_1} {agency?.Name_2}</option>
                                </select>
                                <label htmlFor="email" className="form-label">Agentur
                                    auswählen</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating mb-3 ">
                                <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="viewType"
                                           id="viewType1" value="year" checked={viewType === "year"}
                                           onChange={(e) => setViewType("year")}/>
                                    <label className="form-check-label" htmlFor="viewType1">
                                        Jährliche Übersicht
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input" type="radio" name="viewType"
                                           id="viewType2" value="month" checked={viewType === "month"}
                                           onChange={(e) => setViewType("month")}/>
                                    <label className="form-check-label" htmlFor="viewType2">
                                        Monatliche Übersicht
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                        <i
                            className="fa fa-list"></i> Report
                        erstellen
                    </button>
                </form>
            </div>

            <Loading isLoading={isLoading}/>

            {viewType === "year" ?
                (<YearlyReport report={report} isLoading={isLoading}></YearlyReport>)
                :
                (<MonthlyReport report={report} isLoading={isLoading}></MonthlyReport>)
            }
        </div>
    )
}

export default Reports;