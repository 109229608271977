import * as React from "react";
import ScrollToTop from "../../utils/utils";
import {useAuth} from "../../../context/AuthContext";

export default function Step1({isLoading, includedServices, transactionDetails}) {
    const auth = useAuth();
    return (
        <>
            <ScrollToTop></ScrollToTop>
            <div className="card mb-2">
                <div className="card-header">
                    <h3>Ausgewählte Reise</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-8">
                            {auth.user &&
                                <p className="text-left">
                                    <span className="">
                                        <h4><b>Bucher:</b></h4>
                                        <br/>
                                        {auth.user?.Vorname} {auth.user?.Name} (Agenturnummer: {auth.user.Kundennummer})
                                        <br/>
                                        {auth.user.email}
                                    </span>
                                </p>
                            }

                            <p className="text-left">
                                        <span className="">
                                            <h4><b>Reiseveranstalter:</b></h4>
                                            <br/>
                                            GLOBALIS Erlebnisreisen GmbH
                                            <br/>
                                            Uferstraße 24
                                            <br/>
                                            61137 Schöneck
                                        </span>
                            </p>
                            <p className="text-left">
                                <h4><b>Ihre Reise:</b></h4>
                                <br/>
                                <a target="_blank"
                                   href={`/reise/${transactionDetails.Kurztitel}?id=${transactionDetails.REISE_ID}`}>{transactionDetails.Kurztitel}
                                </a>
                                <br/>
                                <strong>{transactionDetails.DAUER}-tägige Reise
                                    vom {transactionDetails.appointment}
                                </strong>
                                {transactionDetails.TerminPlus}
                                {transactionDetails.goPoint}
                            </p>
                            {includedServices.length > 0 &&
                                <div className="">
                                    <h4>Im Reisepreis bereits enthalten:</h4>
                                    <ul className="list-group">
                                        {includedServices.map((service, i) =>
                                            <li className="list-group-item borderless" key={i}>
                                                <i className="fa fa-check-circle"></i> {service.BEZEICHNUNG_LANG}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
