import React, {useEffect, useState} from 'react';
import Img from "./img";
import {formatCurrency, truncateText} from "../utils/utils";
import Seasons from "./Seasons";

const TripCard = ({trip, cnt = 3}) => {
    let classN = "col-md-3 col-sm-6 col-xs-12";
    if (cnt === 3) {
        classN = "col-md-4 col-sm-6 col-xs-12";
    }

    const [storageItem, setStorageItem] = useState(() =>
        JSON.parse(localStorage.getItem("favourites") || "[]")
    );

    const ids = storageItem.map((savedId) => savedId.ID);
    const isFavourite = ids.includes(trip.ID)

    const handleToggleFavourite = (trip, isFavourite) => {
        if (!isFavourite) {
            const newStorageItem = [...JSON.parse(localStorage.getItem("favourites") || "[]"), trip];
            //console.log(newStorageItem)
            setStorageItem(newStorageItem);
            localStorage.setItem("favourites", JSON.stringify(newStorageItem));
        } else {
            const newStorageItem = JSON.parse(localStorage.getItem("favourites") || "[]").filter((savedId) => savedId.ID !== trip.ID);
            setStorageItem(newStorageItem);
            localStorage.setItem("favourites", JSON.stringify(newStorageItem));
        }

        const event = new CustomEvent('storage');
        window.dispatchEvent(event);
    };

    useEffect(() => {
        const handleStorage = () => {
            setStorageItem(JSON.parse(localStorage.getItem("favourites") || "[]"))
        }

        window.addEventListener('storage', () => {
            handleStorage()
        });
    }, [])

    return (
        trip &&
        <li className={`item-tour product ${classN}`} key={trip.ID}>
            <div className="item_border item-product ">
                <div className="post_images">
                    <a href={`${process.env.PUBLIC_URL}/reise/${encodeURIComponent(trip.Kurztitel)}?id=${trip.ID}`}
                       className="position-relative">
                        <Img imgId={trip.NR_BILD_01}></Img>
                    </a>
                    <button className="tools position-absolute end-0 p-2 button-icon"
                            onClick={(e) => handleToggleFavourite(trip, isFavourite)}>
                        {isFavourite ? (<span className="fav "><i className="fa fa-heart fa-3x"></i></span>) : (
                            <span className="fav "><i className="fa fa-heart-o fa-3x"></i></span>)}
                    < /button>
                </div>
                <div className="wrapper_content">
                    <div className="post_title">
                        <h1>
                            {truncateText(trip.Destination, 50)}
                        </h1>
                        <h3>
                            {truncateText(trip.Kurztitel, 100)}
                        </h3>
                    </div>
                    <span className="post_date">
                        {trip.Reisedauer}
                    </span>
                    <div className="description">
                        <p>
                            {trip.Reiseart}
                        </p>
                        <Seasons tripId={trip.ID}></Seasons>
                        <p>
                            <strong>Reiseverlauf: </strong>
                            {truncateText(trip.Stationen, 100)}
                        </p>
                    </div>
                </div>
                <div className="clearfix p-4"></div>
                <div className="read_more">
                    <div className="item_rating">
                        <div
                            className="price"> ab <span>{formatCurrency(trip.ABPREIS)}</span> p.P.
                        </div>
                    </div>
                    <div className="item_button">
                        <a rel="nofollow"
                           href={`${process.env.PUBLIC_URL}/reise/${encodeURIComponent(trip.Kurztitel)}?id=${trip.ID}`}
                           className="btn btn-primary">zur
                            Reise</a>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default TripCard;