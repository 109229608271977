import React from 'react';
import Klima from "../../klima";

const DraftTripInfoTab = ({trip}) => {
    return (
        <div id="pane-tripInfoTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripInfoTab">
            <div className="col-sm-12 text-left">
                <div className="">
                    <p className="p-wrap">{trip.Reiseinfos}</p>
                    {trip.Reiseklima &&
                        <Klima tId={trip.ID}></Klima>
                    }
                </div>
            </div>
        </div>
    )
}

export default DraftTripInfoTab;