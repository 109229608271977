import React, {useEffect, useState} from 'react';
import YoutubeEmbed from "../../YoutubeEmbed";

const TripPreviewTab = ({trip}) => {

    const [embedId, setEmbedId] = useState(null);
    const [tripFacts, setTripFacts] = useState([]);


    useEffect(() => {
        if (trip.video) {
            const id = trip.video?.Link?.split('=')[1];
            setEmbedId(id)
        }

        for (let i = 1; i <= 11; i++) {
            const number = i < 10 ? '0' + i : i;
            setTripFacts([...tripFacts, number]);
        }
    }, [trip])

    return (
        <div id="pane-tripPreviewTab" className="tab-pane active single-tour-tabs-panel panel entry-content wc-tab"
             role="tabpanel" aria-labelledby="tab-tripPreviewTab">
            {/*<div className="row">
                <div className="col-md-6">
                    {trip.Fact_TOP &&
                        <div className="mb-3">
                            <p className="p-wrap">{nl2br(trip.Fact_TOP)}</p>
                        </div>
                    }
                </div>
            </div>*/}

            {/*<div className="mb-3">
                <ul className="list-group borderless">
                    <div className="borderless">
                        {tripFacts.map((fact) => (
                            trip["Fact_" + fact] &&
                            <li className="list-group-item borderless">
                                <i className="fa fa-check-circle"></i> {trip["Fact_" + fact]}
                            </li>
                        ))}
                    </div>
                </ul>
            </div>*/}

            {trip.video && embedId &&
                <div className="m-3 text-center">
                    <h3>{trip.video.Titel}</h3>
                    <p className="p-wrap">
                        <YoutubeEmbed embedId={embedId}/>
                    </p>
                </div>
            }

            <div className="my-3">
                <p className="p-wrap">
                    {trip.Einleitung}
                </p>
            </div>

            <div className="mb-3">
                <img loading="lazy"
                     src={process.env.REACT_APP_IMG_PATH + trip.map.Bildname + '_low.jpg'}
                     className="center-block" width="70%"/>
            </div>

            <div className="mb-3">
                <p className="mb-3">
                    <strong>Reiseverlauf: </strong>
                    {trip.Stationen}
                </p>
            </div>
        </div>
    )
}

export default TripPreviewTab;