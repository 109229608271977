import React from 'react';
import {formatCurrency} from "../../utils/utils";


const DraftTripServicesTab = ({
                             trip,
                             includedServices,
                             excludedServices,
                             localServices,
                             notIncludedServices
                         }) => {


    return (
        <div id="pane-tripServicesTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripServicesTab">
            <h1 className="text-center">Termine / Preise / Leistungen </h1>
            <div className="row">
                <div className="col-md-12">
                    {includedServices.length > 0 &&
                        <div className="">
                            <h2>Im Reisepreis bereits enthalten:</h2>
                            <ul className="list-group">
                                {includedServices.map((service) =>
                                    <li className="list-group-item borderless">
                                        <i className="fa fa-check-circle"></i>
                                        <span className="p-wrap">{service.BEZEICHNUNG_LANG}</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }


                    {excludedServices.length > 0 &&
                        <div className="my-3">
                            <h2>Auf Wunsch zusätzlich vorab buchbar:</h2>
                            <ul className="list-group">
                                {excludedServices.map((service) =>
                                    <li className="list-group-item borderless">
                                        <i className="fa fa-plus-circle"></i>
                                        <span className="text-left p-wrap"
                                              style={{width: "70%"}}> {service.BEZEICHNUNG_LANG}</span>
                                        <span className="float-end"> {formatCurrency(service.VK_PREIS)} p.P.</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }

                    {localServices.length > 0 &&
                        <div className="my-3">
                            <h2>Nicht im Reisepreis eingeschlossen und nur vor Ort
                                buch- bzw.
                                zahlbar:</h2>
                            <ul className="list-group">
                                {localServices.map((service) =>
                                    <li className="list-group-item borderless">
                                        <i className="fa fa-plus-circle"></i>
                                        <span className="p-wrap"> {service.BEZEICHNUNG_LANG}</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }

                    {notIncludedServices.length > 0 &&
                        <div className="my-3">
                            <h2>Nicht im Reisepreis eingeschlossen:</h2>
                            <ul className="list-group">
                                {notIncludedServices.map((service) =>
                                    <li className="list-group-item borderless">
                                        <i className="fa fa-plus-circle"></i>
                                        <span className="text-left p-wrap"> {service.BEZEICHNUNG_LANG}</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }

                    {trip.Mindestbeteiligung &&
                        <div
                            className="alert alert-warning d-flex align-items-center p-5">
                                                        <span
                                                            className="me-4">
                                                            <i className="fa fa-info-circle fs-1"></i>
                                                           </span>
                            <div className="d-flex flex-column">
                                <p>{trip.Mindestbeteiligung}</p>
                            </div>
                        </div>

                    }
                </div>
            </div>

        </div>
    )
}

export default DraftTripServicesTab;