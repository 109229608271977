import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import Loading from "../partial/Loading";

const Profile = ({agency}) => {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    /*const [contactPersons, setContactPersons] = useState([]);
    const [columns, setColumns] = useState([
        {
            data: 'Vorname', render: function (data, type, row, meta) {
                return `${row.Vorname} ${row.Name}`;
            }
        },
        {data: 'email'},
    ]);*/
    useEffect(() => {
        setIsLoading(false);
        /*setIsLoading(true);
        fetchContactPersonsByAgency(auth.user.Kundennummer, auth.token).then((response) => {
            setContactPersons(response)
            setIsLoading(false);
        });*/
    }, [])

    return (

        <div className="tab-pane fade show active" id="profile" role="tabpanel"
             aria-labelledby="profile-tab">
            <div>
                <h1>Ihr Benutzerprofil</h1>

                <Loading isLoading={isLoading}/>

                <table
                    className="table table-responsive table-borderless table-striped">
                    <tbody>
                    <tr>
                        <th>ID</th>
                        <td>{auth.user?.id}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>{auth.user?.Vorname} {auth.user?.Name}</td>
                    </tr>
                    <tr>
                        <th>E-Mail</th>
                        <td>{auth.user?.email}</td>
                    </tr>
                    </tbody>
                </table>

                <h1>Agentur</h1>

                {agency &&
                    <table
                        className="table table-responsive table-borderless table-striped">
                        <tbody>
                        <tr>
                            <th>Ihre GLOBALIS-Agenturnr.</th>
                            <td>{agency?.Agenturnummer}</td>
                        </tr>
                        <tr>
                            <th>Vermittler</th>
                            <td>{agency?.Name_1} {agency?.Name_2}</td>
                        </tr>
                        <tr>
                            <th>Straße</th>
                            <td>{agency?.Strasse}</td>
                        </tr>
                        <tr>
                            <th>PLZ</th>
                            <td>{agency?.PLZ_Strasse}</td>
                        </tr>
                        <tr>
                            <th>Ort</th>
                            <td>{agency?.Ort_Strasse}</td>
                        </tr>
                        <tr>
                            <th>Telefon</th>
                            <td>{agency?.Telefon}</td>
                        </tr>
                        <tr>
                            <th>Telefax</th>
                            <td>{agency?.Fax}</td>
                        </tr>
                        <tr>
                            <th>Internet</th>
                            <td>{agency?.Website}</td>
                        </tr>
                        </tbody>
                    </table>
                }

                {/*<h1>Folgende Benutzer sind für Ihre Agentur ebenfalls registriert:</h1>

                <DataTable
                    options={
                        {
                            processing: true,
                            responsive: true,
                            select: true,

                        }
                    }
                    data={contactPersons} columns={columns} className="display">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>E-Mail</th>
                    </tr>
                    </thead>
                </DataTable>*/}
            </div>
        </div>
    )
}

export default Profile;