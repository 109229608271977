
import React from "react";
import {formatCurrency} from "../utils/utils";
import Seasons from "../partial/Seasons";

const DraftTripRight = ({trip, tripId}) => {

    const goToTripDatesTab = () => {
        const element = document.getElementById('tab-tripDatesTab');
        element?.click()
        element?.scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div className="summary entry-summary description_single col-xl-4 col-md-6 col-sm-12 order-md-2 affix-sidebar">
            <div className="card mb-2">
                <div className="card-header">
                    <h3 className="fs-3">ab {formatCurrency(trip.ABPREIS)} Pro Person</h3>
                </div>
                <div className="card-body">
                    <div className="clear"></div>
                    <div className="">
                        <div className="post_title">
                            <h1>
                                {trip.Destination}
                            </h1>
                            <h4>
                                {trip.Kurztitel}
                            </h4>
                        </div>
                        <span className="post_date">
                            {trip.Reisedauer}
                        </span>
                        <div className="description mg-bt-6x">
                            <p>
                                {trip.Reiseart}
                            </p>
                            <Seasons tripId={tripId}></Seasons>
                            <p>
                                <strong>Reiseverlauf: </strong>{trip.Stationen}
                            </p>
                        </div>

                        <div className="widget-area align-left col-sm-3 borderless">
                            {trip.Fact_TOP &&
                                <div>
                                    <p>{trip.Fact_TOP}</p>
                                </div>
                            }
                            <ul className="list-group borderless padding-top-4x">
                                <div className="borderless">
                                    {trip.Fact_01 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_01}</span>
                                        </li>
                                    }
                                    {trip.Fact_02 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_02}</span>
                                        </li>
                                    }
                                    {trip.Fact_03 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_03}</span>
                                        </li>
                                    }
                                    {trip.Fact_04 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_04}</span>
                                        </li>
                                    }
                                    {trip.Fact_05 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_05}</span>
                                        </li>
                                    }
                                    {trip.Fact_06 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_06}</span>
                                        </li>
                                    }
                                    {trip.Fact_07 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_07}</span>
                                        </li>
                                    }
                                    {trip.Fact_08 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_08}</span>
                                        </li>
                                    }
                                    {trip.Fact_09 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_09}</span>
                                        </li>
                                    }
                                    {trip.Fact_10 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_10}</span>
                                        </li>
                                    }
                                    {trip.Fact_11 &&
                                        <li className="list-group-item borderless">
                                            <i className="fa fa-check-circle"></i>
                                            <span>{trip.Fact_11}</span>
                                        </li>
                                    }
                                </div>
                            </ul>
                            <div className="d-grid gap-2 col-6 mx-auto">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DraftTripRight;