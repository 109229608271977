import axios from 'axios';
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../../context/AuthContext";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const AxiosInterceptor = ({children}) => {

    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {

        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {

            const originalRequest = error.config;
            //console.log(originalRequest);
            if (error.response.status === 401) {
                if (originalRequest.url.include("protected")) {
                    auth.logOut();
                }
            }

            return Promise.reject(error);
        }


        const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

        return () => axiosInstance.interceptors.response.eject(interceptor);

    }, [navigate, auth])

    return children;
}


export default axiosInstance;
export {AxiosInterceptor}

/*
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {

        const originalRequest = error.config;
        const auth = useAuth();

        alert(error.response.status)

        if (error.response && error.response.status === 401) {
            auth.logOut()
            return;
        }

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const token = localStorage.getItem('token');
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/refresh`, {}, {
                    "headers": {
                        'Accept-Encoding': 'application/json',
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (response) {
                    //update the access token
                    localStorage.setItem('token', response.data.authorization.token);

                    originalRequest.headers['Authorization'] = `Bearer ${response.data.authorization.token}`;

                    return axiosInstance(originalRequest);
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            }
        }

        return Promise.reject(error);
    }
);
*/

//export default axiosInstance;