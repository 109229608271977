import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axiosConfig from "../utils/axiosConfig";
import {formatCurrency, localizeDate, notify} from "../utils/utils";
import {useAuth} from "../../context/AuthContext";
import Loading from "../partial/Loading";

const BookingDetailModal = ({contingent, openedModals}) => {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [modalId] = useState(`details-${contingent.VORGANG_ID}`);

    const [data, setData] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [flightData, setFlightData] = useState([]);
    const [managerData, setManagerData] = useState([]);


    const load = async () => {
        return await axiosConfig.get(`/protected/agency/${auth.user.Kundennummer}/booked/${contingent.VORGANG_ID}`, {
            "headers": {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
            },
        });
    }

    useEffect(() => {
        if (openedModals.includes(modalId)) {
            load().then((result) => {
                setIsLoading(false);
                setData(result.data)
                setManagerData(result.data.manager)
                setBookingData(result.data.bookings)
                setFlightData(result.data.flight)
            }).catch((e) => {
                notify(e.message)
                setData(null)
                setManagerData(null)
                setBookingData([])
                setFlightData([])
                setIsLoading(false);
            })
        }
    }, [openedModals])

    return (
        <div className="modal fade" id={modalId} tabIndex="-1"
             aria-labelledby="deatailsLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="deatailsLabel">
                            {contingent.Destination} - {contingent.Reisetitel}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <Loading isLoading={isLoading}/>
                        <h2>
                            Abreise am {localizeDate(contingent.Abflugtermin)} ab {contingent.Abflughafen}
                        </h2>
                        <h2>
                            Kontingent-Nr.: {contingent.VORGANGNR} - {contingent.VORGANG_ID}
                        </h2>
                        <h2>
                            Reiseart: {data.REISEART}
                        </h2>
                        <table className="dashboard table table-striped">
                            <thead>
                            <tr className="header">
                                <th colSpan="2">Ihre GLOBALIS-Ansprechpartner</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{managerData?.Vorname} {managerData?.Name}</td>
                            </tr>

                            <tr>
                                <th>Telefon</th>
                                <td>{managerData?.Telefon}</td>
                            </tr>
                            <tr>
                                <th>E_Mail</th>
                                <td>{managerData?.email}</td>
                            </tr>
                            </tbody>
                        </table>

                        {flightData.showFlightInfo &&
                            <>
                                <table className="dashboard table table-responsive table-striped">
                                    <thead>
                                    <tr className="header">
                                        <th colSpan="6">Flugdaten</th>
                                    </tr>
                                    <tr>
                                        <th>Datum</th>
                                        <th>Fluggesellschaft</th>
                                        <th>Flugnummer</th>
                                        <th>Flugstrecke</th>
                                        <th>Ab</th>
                                        <th>An</th>
                                    </tr>
                                    <tr>
                                        <th colSpan="6">Hinflug</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {!flightData.outboundStop ? (
                                        <tr>
                                            <td>{localizeDate(flightData.outboundDepartureFlightDate)}</td>
                                            <td>{flightData.outboundAirline}</td>
                                            <td>{flightData.outboundFlightNr}</td>
                                            <td>{flightData.outboundDepartureFlightAirportName} ({flightData.outboundDepartureFlightAirport})
                                                - {flightData.outboundArrivalFlightAirportName} ({flightData.outboundArrivalFlightAirport})
                                            </td>
                                            <td>{flightData.showFlightTime ? flightData.outboundDepartureFlightStartTime : ""}</td>
                                            <td>{flightData.showFlightTime ? flightData.outboundDepartureFlightEndTime : ""}</td>
                                        </tr>
                                    ) : (
                                        <>
                                            <tr>
                                                <td>{localizeDate(flightData.outboundDepartureFlightDate)}</td>
                                                <td>{flightData.outboundAirline}</td>
                                                <td>{flightData.outboundDepartureFlightNr}</td>
                                                <td>{flightData.outboundDepartureFlightAirportName} ({flightData.outboundDepartureFlightAirport})
                                                    - {flightData.outboundStopDepartureFlightAirportName} ({flightData.outboundStopDepartureFlightAirport})
                                                </td>

                                                <td>{flightData.showFlightTime ? flightData.outboundDepartureFlightStartTime : ""}</td>
                                                <td>{flightData.showFlightTime ? flightData.outboundStopArrivalFlightTime : ""}</td>
                                            </tr>
                                            <tr>
                                                <td>{localizeDate(flightData.outboundStopDepartureFlightDate)}</td>
                                                <td>{flightData.outboundAirline}</td>
                                                <td>{flightData.outboundStopFlightNr}</td>
                                                <td>{flightData.outboundStopDepartureFlightAirportName} ({flightData.outboundStopDepartureFlightAirport})
                                                    - {flightData.outboundArrivalFlightAirportName} ({flightData.outboundArrivalFlightAirport})
                                                </td>
                                                <td>{flightData.showFlightTime ? flightData.outboundStopDepartureFlightTime : ""}</td>
                                                <td>{flightData.showFlightTime ? flightData.outboundArrivalFlightTime : ""}</td>
                                            </tr>
                                        </>
                                    )
                                    }
                                    <tr>
                                        <th colSpan="6">Rückflug</th>
                                    </tr>


                                    {!flightData.returnStop ? (
                                        <tr>
                                            <td>{localizeDate(flightData.returnDepartureFlightDate)}</td>
                                            <td>{flightData.returnAirline}</td>
                                            <td>{flightData.returnFlightNr}</td>
                                            <td>{flightData.returnDepartureFlightAirportName} ({flightData.returnDepartureFlightAirport})
                                                - {flightData.returnArrivalFlightAirportName} ({flightData.returnArrivalFlightAirport})
                                            </td>
                                            <td>{flightData.showFlightTime ? flightData.returnArrivalFlightStartTime : ""}</td>
                                            <td>{flightData.showFlightTime ? flightData.returnArrivalFlightEndTime : ""}</td>
                                        </tr>
                                    ) : (
                                        <>
                                            <tr>
                                                <td>{localizeDate(flightData.returnDepartureFlightDate)}</td>
                                                <td>{flightData.returnAirline}</td>
                                                <td>{flightData.returnFlightNr}</td>
                                                <td>{flightData.returnDepartureFlightAirportName} ({flightData.returnDepartureFlightAirport})
                                                    - {flightData.returnStopDepartureFlightAirportName} ({flightData.returnStopDepartureFlightAirport})
                                                </td>
                                                <td>{flightData.showFlightTime ? flightData.returnDepartureFlightTime : ""}</td>
                                                <td>{flightData.showFlightTime ? flightData.returnStopArrivalFlightTime : ""}</td>
                                            </tr>
                                            <tr>
                                                <td>{localizeDate(flightData.returnArrivalFlightDate)}</td>
                                                <td>{flightData.returnAirline}</td>
                                                <td>{flightData.returnStopFlightNr}</td>
                                                <td>{flightData.returnStopArrivalFlightAirportName} ({flightData.returnStopArrivalFlightAirport})
                                                    - {flightData.returnArrivalFlightAirportName} ({flightData.returnArrivalFlightAirport})
                                                </td>
                                                <td>{flightData.showFlightTime ? flightData.returnStopDepartureFlightTime : ""} </td>
                                                <td>{flightData.showFlightTime ? flightData.returnArrivalFlightEndTime : ""}</td>
                                            </tr>
                                        </>
                                    )
                                    }
                                    {flightData.showFlightTime ?
                                        (
                                            <tr>
                                                <td colSpan="6"><i className="fa fa-info-circle"></i> Angegebene
                                                    Uhrzeiten sind
                                                    Ortszeiten. Änderungen der geplanten Flugzeiten im Rahmen der
                                                    gesetzlichen
                                                    und
                                                    vertraglichen Möglichkeiten behalten wir uns vor
                                                    Die genauen Flugzeiten/-daten erhalten Sie mit den Reiseunterlagen.
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan="6"><i className="fa fa-info-circle"></i> Die genauen
                                                    Flugzeiten/-daten
                                                    erhalten Sie mit den
                                                    Reiseunterlagen.
                                                </td>
                                            </tr>
                                        )
                                    }
                                    < /tbody>
                                </table>
                            </>
                        }

                        {data.bookingBookedParticipants?.length > 0 ? (
                            <table className="dashboard table table-responsive table-striped">
                                <thead>
                                <tr className="header">
                                    <th colSpan="5">Gebuchte Reiseteilnehmer</th>
                                </tr>
                                <tr>
                                    <th>Vorgang</th>
                                    <th>Teilnehmer:</th>
                                    <th>Unterkunft</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.bookingBookedParticipants?.map((bookedParticipant) => (
                                    <tr>
                                        <td>{bookedParticipant.BuLiNummer}</td>
                                        <td>{bookedParticipant.Vorname} {bookedParticipant.Name}</td>
                                        <td>{bookedParticipant.BEZ_LANG} ({bookedParticipant.BEZ_KURZ})</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (<h3 className="p-3"><i className="fa fa-warning"></i> Keine Gebuchte Reiseteilnehmer !
                        </h3>)}

                        {data.bookingCanceledParticipants?.length > 0 &&
                            <table className="dashboard table table-responsive table-striped">
                                <thead>
                                <tr className="header">
                                    <th colSpan="5"> Stornierte Teilnehmer</th>
                                </tr>
                                <tr>
                                    <th>Vorgang</th>
                                    <th>Teilnehmer:</th>
                                    <th>Unterkunft</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.bookingCanceledParticipants?.map((bookedParticipant) => (
                                    <tr>
                                        <td>{bookedParticipant.BuLiNummer}</td>
                                        <td>{bookedParticipant.Vorname} {bookedParticipant.Name}</td>
                                        <td>{bookedParticipant.BEZ_LANG} ({bookedParticipant.BEZ_KURZ})</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        }
                        <table className="dashboard table table-responsive table-striped">
                            <thead>
                            <tr className="header">
                                <th colSpan="5">Buchhaltungs-Daten</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Vorgang</th>
                                <th>Rechnungsempfänger</th>
                                <th>Rechnungsbetrag</th>
                                <th>VorgaZahlungseingangng</th>
                                <th>Saldo</th>
                            </tr>
                            {
                                bookingData.map((row) => (
                                        <>
                                            <tr>
                                                <td>{row.BuLiNummer}</td>
                                                <td>{row.Rechnungsempfaenger}</td>
                                                <td>{formatCurrency(row.debSoll)}</td>
                                                <td>{formatCurrency(row.debHaben)}</td>
                                                <td>{formatCurrency(row.debSoll - row.debHaben)}</td>
                                            </tr>
                                        </>
                                    )
                                )
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingDetailModal;