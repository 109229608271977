import React from 'react';

const DraftTripReferencesTab = ({trip, references}) => {

    return (
        <div id="pane-tripReferencesTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripReferencesTab">
            <div id="reviews" className="travel_tour-Reviews">
                <div id="comments">
                    <h2 className="travel_tour-Reviews-title">1 review for
                        <span>Kiwiana Panorama</span></h2>
                    {references?.map((reference) => (
                        <ol className="commentlist">
                            <li itemScope="" itemType="http://schema.org/Review"
                                className="comment byuser comment-author-physcode bypostauthor even thread-even depth-1"
                                id="li-comment-62">
                                <div id="comment-62" className="comment_container">
                                    <div className="comment-text">
                                        <div className="star-rating"
                                             title="Rated 4 out of 5">
                                            <i className="fa fa-star"></i><i
                                            className="fa fa-star"></i><i
                                            className="fa fa-star"></i><i
                                            className="fa fa-star"></i><i
                                            className="fa fa-star"></i>
                                        </div>
                                        <div className="avatar avatar-60 photo">
                                            <i className="fa fa-user-circle text-secondary"></i>
                                        </div>
                                        <p className="meta">
                                            <strong>Anonymer Nutzer</strong> –
                                            <time
                                                dateTime="2017-01-24T03:54:04+00:00">{reference.InsertAm}
                                            </time>
                                            :
                                        </p>
                                        <div className="description">
                                            <p>{reference.Beschreibung}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    ))}
                </div>
                <div className="clear"></div>
            </div>
        </div>
    )
}

export default DraftTripReferencesTab;