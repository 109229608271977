import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";

const Logout = () => {
    const auth = useAuth();

    useEffect(() => {
        auth.logOut()
    }, [])

    return (
        <></>
    )
}

export default Logout;