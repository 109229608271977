import * as React from "react";
import {useFormContext} from "react-hook-form";
import ScrollToTop from "../../utils/utils";

export default function Step4({countries}) {
    const methods = useFormContext();
    return (
        <>
            <ScrollToTop></ScrollToTop>
            <div className="card">
                <div className="card-header">
                    <h3>Kundendaten / Rechnungsempfänger</h3>
                </div>
                <div className="card-body">
                    <div className="col-ms-8">
                        <div className="travel_tour-billing-fields">

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>Anrede<span
                                        className="required">*</span></label>
                                    <select className="form-control"
                                            {...methods.register(`customer.gender`, {required: true})}
                                            onClick={() => {
                                                methods.clearErrors(`customer.gender`)
                                            }}>
                                        <option value="">Bitte wählen</option>
                                        <option value="2">Frau</option>
                                        <option value="3">Herr</option>
                                    </select>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['gender'] &&
                                        <p className="error">Bitte
                                            Anrede auswählen</p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        type="text" className="form-control"
                                        id="title"
                                        placeholder=""
                                        {...methods.register(`customer.title`, {required: false})}/>
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-wide">
                                <div className="form-group">
                                    <label>
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        placeholder=""
                                        {...methods.register(`customer.email`, {
                                            required: true,
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                            },
                                        })}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['email'] &&
                                        <p className="error">E-Mail ist erforderlich </p>}

                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['email']?.message &&
                                        <p className="error">{methods.formState.errors.customer.email.message}</p>}
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>
                                        Vorname<span className="required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firstName"
                                        placeholder=""
                                        {...methods.register(`customer.firstname`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['firstname'] &&
                                        <p className="error">Vorname ist erforderlich</p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>
                                        Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control "
                                        id="lastname"
                                        placeholder=""
                                        {...methods.register(`customer.lastname`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['lastname'] &&
                                        <p className="error">Name ist erforderlich</p>}
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first ">
                                <div className="form-group">
                                    <label>Straße / Nr.
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control"
                                        id="streetAndNo"
                                        placeholder=""
                                        {...methods.register(`customer.streetAndNo`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['streetAndNo'] &&
                                        <p className="error">Straße / Nr. ist erforderlich </p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last ">
                                <div className="form-group">
                                    <label>
                                        Ort
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control "
                                        id="city"
                                        placeholder=""
                                        {...methods.register(`customer.city`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['city'] &&
                                        <p className="error">Ort ist erforderlich </p>}
                                </div>
                            </p>
                            <div className="clear"></div>
                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>
                                        PLZ<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text" className="form-control "
                                        id="postalCode"
                                        placeholder=""
                                        {...methods.register(`customer.postalCode`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['postalCode'] &&
                                        <p className="error">PLZ ist erforderlich </p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>
                                        Land<span className="required">*</span>
                                    </label>
                                    <select id="country"
                                            defaultValue="Deutschland"
                                            className="form-control"
                                            {...methods.register(`customer.country`, {required: true})}>
                                        <option value="">Bitte Wählen</option>
                                        {countries.map((country) => (
                                            <option value={country.Kuerzel}>{country.Land}</option>
                                        ))}
                                    </select>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['country'] &&
                                        <p className="error">Land ist erforderlich </p>}
                                </div>
                            </p>

                            <div className="clear"></div>

                            <p className="form-row form-row form-row-first">
                                <div className="form-group">
                                    <label>Telefon<span className="required">*</span></label>
                                    <input type="text"
                                           className="form-control "
                                           id="phone"
                                           placeholder=""
                                           {...methods.register(`customer.phone`, {required: true})}/>
                                    {methods.formState.errors['customer'] !== undefined && methods.formState.errors['customer']['phone'] &&
                                        <p className="error">Telefon ist erforderlich </p>}
                                </div>
                            </p>

                            <p className="form-row form-row form-row-last">
                                <div className="form-group">
                                    <label>
                                        Mobil / Arbeit</label>
                                    <input
                                        type="text" className="form-control"
                                        placeholder=""
                                        {...methods.register(`customer.mobile`, {required: false})}/>
                                </div>
                            </p>

                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
