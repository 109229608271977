import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Loading from "./Loading";
import {fetchCalendarTrips, formatCurrency, mapTripType} from "../utils/utils";
import {BrowserView, MobileView} from "react-device-detect";

const CalendarBody = ({year, month, search}) => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get("reiseart");
    const dest = searchParams.get("reiseziel");
    const [calendarTrips, setCalendarTrips] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const cType = mapTripType(type);
        fetchCalendarTrips(month, year, cType, dest, search).then((data) => {
            setCalendarTrips([...calendarTrips, ...data]);
            setIsLoading(false);
        });
    }, []);

    return (
        <div className="accordion-body">
            <Loading isLoading={isLoading}/>
            <table className="table table-responsive table-borderless borderless table-striped" border="0">
                <BrowserView>
                    <tbody>
                    {calendarTrips?.map((post, index) => (
                        <tr key={index}>
                            <td>
                                <img
                                    src={process.env.REACT_APP_IMG_PATH + post.img.Bildname + '_small.jpg'}
                                    width="80"/></td>
                            <td>{post.termin}</td>
                            <td>ab {post.Abflughafen}</td>
                            <td>ab {formatCurrency(post.ProspektPreis)}</td>
                            <td>{post.Kurztitel}</td>
                            <td>
                                <a className="btn btn-primary"
                                   href={'/reise/' + encodeURIComponent(post.Kurztitel) + '?id=' + post.ID}>Zur
                                    Reise</a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </BrowserView>
                <MobileView>
                    <tbody>
                    {calendarTrips?.map((post, index) => (
                        <tr key={index}>
                            <td>{post.termin}</td>
                            <td>
                                ab {post.Abflughafen}
                            </td>
                            <td>
                                ab {formatCurrency(post.ProspektPreis)}
                            </td>
                            <td>
                                {post.Kurztitel}
                                <br/>
                                <a className="btn btn-primary"
                                   href={'/reise/' + encodeURIComponent(post.Kurztitel) + '?id=' + post.ID}>Zur
                                    Reise</a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </MobileView>
            </table>
        </div>
    )
}

export default CalendarBody;