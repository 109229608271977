import React from 'react';
import {formatCurrency} from "../../utils/utils";
import Loading from "../../partial/Loading";



const DraftTripDatesTab = ({
                          trip,
                          appointments,
                          loading,
                          accommodations
                      }) => {


    return (
        <div id="pane-tripDatesTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab"
             role="tabpanel" aria-labelledby="tab-tripDatesTab">
            <h1 className="text-center">Termine / Preise</h1>
            <div className="row">
                <div className="col-md-12">
                    <h4>Wählen Sie Ihren gewünschten Reisetermin aus:</h4>
                    <table className="table table-responsive borderless table-striped">
                        <thead>
                        <th>Reisetermin</th>
                        <th>Preis p.P.</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        </thead>
                        <tbody>
                        {loading &&
                            <tr>
                                <td colSpan="5"><Loading isLoading={loading} showText={true}></Loading></td>
                            </tr>
                        }

                        {Object.keys(appointments)?.map((key, index) => (
                            <>
                                <tr>
                                    <td colSpan="5"><h3><strong>{key}</strong></h3></td>
                                </tr>
                                {appointments[key].map((appointment) => (
                                    <tr>
                                        <td> {appointment.appointment}</td>
                                        <td> ab {formatCurrency(appointment.ProspektPreis)}</td>
                                        <td> {appointment.TerminPlus} </td>
                                        <td>
                                            {appointment.rest < 10 && appointment.rest > 1 ?
                                                <span
                                                    className="text-bg-danger">{'Noch ' + appointment.rest + ' Plätze!'}</span> : ''
                                            }
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ))}
                        </tbody>
                    </table>
                    <h4>Unterkünfte und Zuschläge pro Person</h4>
                    <table className="table-responsive borderless table-striped">
                        <thead>
                        <tr>
                            <th>
                                Unterkunft / Belegung mit
                            </th>
                            <th>
                            </th>
                            <th>
                            </th>
                            <th>
                                Zuschlag ab
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {accommodations.map((accommodation) => (
                            <tr>
                                <td>{accommodation.BEZ_LANG}</td>
                                <td>{accommodation.VERFUEGBAR === "N" ?
                                    <strong>- z.Z. ausgebucht</strong> : ''}</td>
                                <td>{accommodation.BELEGUNG} Pers.&nbsp;</td>
                                <td>{accommodation.ZUSCHLAG !== 0 ? formatCurrency(accommodation.ZUSCHLAG) : ''}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div
                        className="alert alert-warning d-flex align-items-center p-5">
                                                        <span
                                                            className="me-4">
                                                            <i className="fa fa-info-circle fs-1"></i>
                                                           </span>
                        <div className="d-flex flex-column">
                            <p className="p-wrap">
                                Flugplan-, Hotel- und Programmänderungen bleiben
                                bei gleichem
                                Leistungsumfang ausdrücklich vorbehalten. Es gelten unsere
                                allgemeinen Reisebedingungen.
                                Zwischenverkauf bis zur verbindlichen Buchungsbestätigung bei allen
                                Reisen vorbehalten.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DraftTripDatesTab;