import React from 'react';

const TripProgrammTab = ({trip}) => {
    const tripDayDescriptions = [];
    for (let i = 1; i <= 20; i++) {
        const number = i < 10 ? '0' + i : i;
        tripDayDescriptions.push(number);
    }

    return (
        <div id="pane-tripProgrammTab" className="tab-pane single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripProgrammTab">
            <div className="">
                {tripDayDescriptions.map((day) => (
                    trip["Tag_" + day] &&
                    <div className="interary-item">
                        <p className={trip["Tag_" + day].includes("Tag") ? '' : 'hidden'}>
                            <span className="icon-left">{Number(day)}</span></p>
                        <div className="item_content">
                            <h2><strong>{trip["Tag_" + day]}</strong></h2>
                            <p className="p-wrap">{trip["Tag_" + day + "_Programm"]}</p>
                            <p className="p-wrap">{trip["Tag_" + day + "_Zusatzausflug"]}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TripProgrammTab;