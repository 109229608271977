import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "./AuthContext";

const ProtectedRoute = () => {
    const user = useAuth();
    // Check if the user is authenticated
    if (!user.token) {
        //console.log("not authenticated, redirect to the login page")
        // If not authenticated, redirect to the login page
        return <Navigate to="/login"/>;
    }

    // If authenticated, render the child routes
    return <Outlet/>;
};

export default ProtectedRoute;