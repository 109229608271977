import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import axiosConfig from "../utils/axiosConfig";
import {diffYears, localizeDate, notify} from "../utils/utils";
import Loading from "../partial/Loading"; // Optional Theme applied to the Data Grid
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import "datatables.net-dt/css/dataTables.dataTables.css";
import bootstrap from 'bootstrap/dist/js/bootstrap.js';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.html5';
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import languageDe from "datatables.net-plugins/i18n/de-DE.mjs";

DataTable.use(DT);
DT.Buttons.jszip(jszip);
DT.Buttons.pdfMake(pdfmake);
const Booked = ({agency, sendContingentDataToParent, sendModalOpenedIdToParent}) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const currentYear = new Date().getFullYear();
    const [from, setFrom] = useState(`${currentYear}-01-01`);
    const [to, setTo] = useState(`${currentYear}-12-31`);
    const [agencyNo, setAgencyNo] = useState(auth.user.Kundennummer);
    const [booked, setBooked] = useState([]);
    const [columns, setColumns] = useState([
        {data: 'VORGANGNR'},
        {
            data: 'Abflugtermin', render: function (data) {
                return localizeDate(data, false);
            }
        },
        {data:"Destination"},
        {
            data: 'Reise_ID', render: function (data, type, row, meta) {
                return `<a target="_blank" href="/reise/${row.Reisetitel}?id=${row.Reise_ID}">${row.Reisetitel}</a>`;
            }
        },
        /*{
            data: 'VORGANG_ID', render: function (data, type, row, meta) {
                return `<a target="_blank" href="/dashboard/booked/details?tNo=${row.VORGANGNR}&tId${row.VORGANG_ID}">Details Öffnen</a>`;
            }
        },*/
        /* {
             data: 'VORGANG_ID', render: function (data, type, row, meta) {
                 return `<button type="button" class="btn btn-warning" data-bs-toggle="modal"
                             data-bs-target="#details-${row.VORGANG_ID}">Details Öffnen
                             </button>`;
             }
         },*/
        {data: "VORGANG_ID"}
    ]);

    const load = async () => {
        return await axiosConfig.get(`/protected/agency/${agencyNo}/booked/${from}/${to}`, {
            "headers": {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
            },
        });
    }

    const showModal = (row) => {
        const element = document.getElementById(`details-${row.VORGANG_ID}`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.show()
        sendModalOpenedIdToParent(`details-${row.VORGANG_ID}`)
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (agencyNo === 0) {
            notify("Bitte ein Agentur auswählen");
            return
        }

        if (diffYears(from, to) > 1) {
            notify(`Bitte Maximal ein jahr Zeitraum auswählen z.B. 01.01.${currentYear} - 31.12.${currentYear}`);
            return
        }

        setIsLoading(true);

        load().then((result) => {
            setIsLoading(false);
            if (result.data.length === 0) {
                setBooked([])
                sendContingentDataToParent([]);
            } else {
                setBooked(result.data)
                sendContingentDataToParent(result.data);
            }
        }).catch((e) => {
            notify(e.message)
            setIsLoading(false);
        })
    };

    useEffect(() => {
    }, [])

    return (
        <div className="tab-pane fade " id="booked" role="tabpanel"
             aria-labelledby="report-tab">

            <div className="mb-5">
                <h1>Ihre gebuchte Kontingente im Überblick</h1>
                <p><i className="fa fa-info-circle"></i> Damit Sie stets auf dem Laufenden sind, erhalten Sie hier einen
                    Überblick über Ihre gebuchten Kontingente. Klicken Sie auf das "Details Öffnen" und Sie finden die
                    Details zu Ihrem jeweiligen Kontingent.</p>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="date" className="form-control"
                                       name="from"
                                       value={from}
                                       onChange={(e) => setFrom(e.target.value)}
                                       id="from" placeholder="Zeitraum von"

                                />
                                <label htmlFor="from" className="form-label">Zeitraum
                                    von:</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="date" className="form-control"
                                       name="to"
                                       value={to}
                                       onChange={(e) => setTo(e.target.value)}
                                       id="to" placeholder="Zeitraum bis"
                                />
                                <label htmlFor="to" className="form-label">Zeitraum
                                    bis</label>
                            </div>
                        </div>
                    </div>


                    <div className="form-floating mb-3">
                        <select className="form-select" aria-label="Default select example"
                                onChange={(e) => setAgencyNo(e.target.value)}>
                            <option
                                value={agency?.Agenturnummer}>{agency?.Name_1} {agency?.Name_2}</option>
                        </select>
                        <label htmlFor="email" className="form-label">Agentur
                            auswählen</label>

                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                        <i
                            className="fa fa-list"></i> Buchungen laden
                    </button>
                </form>
            </div>

            <Loading isLoading={isLoading}/>
            {booked?.length > 0 && !isLoading &&
                <>
                    <DataTable
                        slots={{
                            4: (data, row) => (
                                <button type="button" class="dashboard btn" onClick={() => showModal(row)}>Details
                                    Öffnen</button>
                            )
                        }}
                        options={
                            {
                                language: languageDe,
                                responsive: true,
                                select: true,
                                pageLength: -1,
                                lengthMenu: [[50, 100, 500, 1000, -1], [50, 100, 500, 1000, "All"]],
                                layout: {
                                    bottomStart: 'buttons',
                                },
                            }
                        }
                        data={booked} columns={columns} className="display">
                        <thead>
                        <tr>
                            <th>Kontingent</th>
                            <th>Abreise</th>
                            <th>Reiseziel</th>
                            <th>Reise</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                    </DataTable>
                </>
            }
        </div>
    )
}

export default Booked;