import React, {useEffect} from 'react';
import MultiStepBookingForm from "./MultiStepBookingForm";
import HeaderSubPageTiny from "./partial/HeaderSubPageTiny";

const Checkout = ({partner}) => {

    useEffect(() => {
        document.title = `${partner.name.toUpperCase()} - Checkout`;
    }, [partner]);

    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Buchung'} subtitle={'Buchung'}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        <MultiStepBookingForm></MultiStepBookingForm>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Checkout;