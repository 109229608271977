import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import TripRight from "./partial/TripRight";
import TripLeft from "./partial/TripLeft";
import TripCard from "./partial/TripCard";
import {
    fetchExcludedServices,
    fetchIncludedServices,
    fetchLocalServices,
    fetchNotIncludedServices,
    fetchRelatedTrips,
    fetchTrip,
    fetchTripAccommodations,
    fetchTripAppointments,
    fetchTripReferences,
    fetchTripSeeings
} from "./utils/utils";
import Loading from "./partial/Loading";

const Trip = ({partner}) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tripId = searchParams.get("id");
    const [trip, setTrip] = useState([]);
    const [includedServices, setIncludedServices] = useState([]);
    const [excludedServices, setExcludedServices] = useState([]);
    const [localServices, setLocalServices] = useState([]);
    const [notIncludedServices, setNotIncludedServices] = useState([]);
    const [seeings, setSeeings] = useState([]);
    const [references, setReferences] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [accommodations, setAccommodations] = useState([]);
    const [relatedTrips, setRelatedTrips] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);


    // Call fetchData on component mount
    useEffect(() => {
        fetchTrip(tripId).then((result) => {
            setTrip(result);
            setIsLoading(false)
            document.title = `${result.Reiseart} - ${result.Destination} - ${result.Kurztitel}`;
        }).catch((error) => {
            //if (error.response.status === 404) {
            navigate("/notfound");
            //}
        });
        fetchIncludedServices(tripId).then((result) => {
            setIncludedServices(result);
        });
        fetchExcludedServices(tripId).then((result) => {
            setExcludedServices(result);
        });
        fetchLocalServices(tripId).then((result) => {
            setLocalServices(result);
        });
        fetchNotIncludedServices(tripId).then((result) => {
            setNotIncludedServices(result);
        });
        fetchTripSeeings(tripId).then((result) => {
            setSeeings(result);
        });
        fetchTripReferences(tripId).then((result) => {
            setReferences(result);
        });
        fetchTripAppointments(tripId).then((result) => {
            const groupByAirport = Object.groupBy(result, product => {
                return product.Abflughafen;
            });
            setAppointments(groupByAirport);
            setIsAppointmentsLoading(false)
        });
        fetchTripAccommodations(tripId).then((result) => {
            setAccommodations(result);
        });
        fetchRelatedTrips(tripId).then((result) => {
            setRelatedTrips(result);
        });
    }, [partner]);
    return (
        <div className="single-product travel_tour-page travel_tour">
            <div className="site wrapper-content">
                <div className="top_site_main" style={{backgroundColor: '#fff'}}>
                </div>
                <section className="content-area single-woo-tour">
                    <div className="container-fluid">
                        <div className="tb_single_tour product">
                            <div className="top_content_single row">
                                <Loading isLoading={isLoading} showText={false}></Loading>
                                <TripRight trip={trip} tripId={tripId}></TripRight>
                                <TripLeft
                                    isLoading={isLoading}
                                    tripId={tripId}
                                    trip={trip}
                                    references={references}
                                    seeings={seeings}
                                    appointments={appointments}
                                    isAppointmentsLoading={isAppointmentsLoading}
                                    accommodations={accommodations}
                                    includedServices={includedServices}
                                    excludedServices={excludedServices}
                                    localServices={localServices}
                                    notIncludedServices={notIncludedServices}
                                ></TripLeft>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        {relatedTrips.length > 0 &&
                            <div className="related tours">
                                <div className="row text-center">
                                    <div>
                                        <h2>Ähnliche, ebenfalls einzigartige Reisen:</h2>
                                    </div>
                                    <ul className="tours products wrapper-tours-slider">
                                        {relatedTrips?.map((trip) => (
                                            <TripCard trip={trip}></TripCard>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Trip;