import React, {useEffect, useState} from 'react';
import Axios from "axios";

const axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const Seasons = ({tripId}) => {
    const [seasons, setSeasons] = useState([]);
    const fetchSeasons = async () => {
        try {
            const response = await axiosInstance.get(`/trips/${tripId}/seasons`);
            //console.log(response.data)
            setSeasons(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchSeasons();
    }, []);

    return (
        tripId &&
        <p>

            {seasons.map((season, i) => (
                <span key={i}>✓ {season} </span>
            ))}
        </p>
    )
}

export default Seasons;