import React from 'react';
import {HashLink} from "react-router-hash-link";
import Copyright from "./Copyright";
import {BannerModal, FavModal, SearchModal} from "./Modals";
import Cookie from "../CookieConsent";
import {formatPhoneNumber, getPhoneNumber} from "../utils/utils";
import {Link} from "react-router-dom";

const Footer = ({partner}) => {
    return (
        <>
            <div className="wrapper-footer wrapper-footer-newsletter">
                <div className="main-top-footer">
                    <div className="container">
                        <div className="row">
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Kontakt</h3>
                                <div className="textwidget">
                                    <div className="footer-info">
                                        <ul className="contact-info">
                                            <li> GLOBALIS Erlebnisreisen GmbH</li>
                                            <li><i className="fa fa-map-marker fa-fw"></i>Uferstraße 24</li>
                                            <li><i className="fa fa-map-marker fa-fw"></i>61137 Schöneck</li>
                                            <li><i className="fa fa-phone fa-fw"></i>
                                                <a href={`tel:${formatPhoneNumber(partner.phone)}`}>{getPhoneNumber(partner.phone)}</a>
                                            </li>
                                            <li>
                                                <i className="fa fa-envelope fa-fw"></i><a
                                                href={`mailto:${partner.email}`}>
                                                {partner.email}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Rechtliches</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><Link to="/datenschutz">Datenschutz</Link></li>
                                        <li><Link to="/impressum">Impressum</Link></li>
                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">Reisebedingungen</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><Link to="/informationen/rkv">Reiseversicherungen</Link></li>
                                        <li><Link to="/informationen/reisebedingungen">Reisebedingungen</Link></li>
                                        <li><Link to="/informationen/zahlungsweisen">Zahlungsweisen</Link></li>
                                        <li><HashLink
                                            to="/informationen/reisebedingungen#Storno">Stornogebühren</HashLink>
                                        </li>
                                        <li><Link to="/informationen/faq">Oft gestellte Fragen</Link></li>

                                    </ul>
                                </div>
                            </aside>
                            <aside className="col-sm-3 widget_text"><h3 className="widget-title">GLOBALIS</h3>
                                <div className="textwidget">
                                    <ul className="menu list-arrow">
                                        <li><Link to="/globalis">Die GLOBALIS Philosophie</Link></li>
                                        <li><Link to="/referenzen">Referenzen</Link></li>
                                        <li><Link to="/karriere">Karriere</Link></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/kontakt/`}>Kontakt</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/login/`}>Vermittler login</a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                <Copyright partner={partner}></Copyright>
            </div>
            {/* <Partners></Partners>*/}
            <SearchModal></SearchModal>
            <FavModal></FavModal>
            <BannerModal></BannerModal>
            <Cookie partner={partner}></Cookie>
        </>
    )
}

export default Footer;