import React from 'react';

const Copyright = ({partner}) => {
    return (
        <div className="container wrapper-copyright">
            <div className="row">
                <div className="col-md-6 text-left float-start p-5">
                    <img src={`${process.env.PUBLIC_URL}/images/logos/footer/${partner.footer_logo}`}
                         width="300px"
                         alt={partner.name}
                    />
                    {partner.agency_no === 91543 &&
                        <div className="col-md-6 text-left float-start p-3">
                            <aside id="text-5" className="widget_text">
                                <div className="textwidget">
                                    <ul className="footer_menu">
                                        <li className="p-1">
                                            <a target="_blank" href="https://x.com/sz"><i
                                                className="fa fa-twitter fs-1"></i></a>
                                        </li>
                                        <li className="p-1">
                                            <a target="_blank" href="https://www.facebook.com/ihre.sz/"><i
                                                className="fa fa-facebook fs-1"></i></a>
                                        </li>
                                        <li className="p-1">
                                            <a target="_blank" href="https://www.instagram.com/sz/"><i
                                                className="fa fa-instagram fs-1"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    }
                </div>
                <div className="col-md-6 text-left float-end p-5">
                    <p>
                        Verantwortlich für den Inhalt dieser Angebote und für die Durchführung der angebotenen Reisen
                        ist der Reiseveranstalter: <br/>
                        GLOBALIS Erlebnisreisen GmbH<br/>
                        Uferstraße 24, 61137 Schöneck
                    </p>
                </div>
            </div>

            <div className="container wrapper-copyright">
                <div className="row">
                    <div className="col-sm-6">
                        <div><p>Copyright © 2008 - 2024: GLOBALIS Erlebnisreisen GmbH </p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Copyright;