import React from 'react';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import DraftTripPreviewTab from "./part/DraftTripPreviewTab";
import DraftTripProgrammTab from "./part/DraftTripProgrammTab";
import DraftTripHotelTab from "./part/DraftTripHotelTab";
import DraftTripServicesTab from "./part/DraftTripServicesTab";
import DraftTripSeeingsTab from "./part/DraftTripSeeingsTab";
import DraftTripInfoTab from "./part/DraftTripInfoTab";
import DraftTripReferencesTab from "./part/DraftTripReferencesTab";
import DraftTripDatesTab from "./part/DraftTripDatesTab";


const DraftTripLeft = ({
                           tripId,
                           trip,
                           seeings,
                           references,
                           appointments,
                           isAppointmentsLoading,
                           accommodations,
                           includedServices,
                           excludedServices,
                           localServices,
                           notIncludedServices
                       }) => {


    const placeholderImages = [{
        thumbnail: process.env.PUBLIC_URL + '/images/img-placeholder.webp',
        original: process.env.PUBLIC_URL + '/images/img-placeholder.webp',
    }];
    const images = [];
    trip.images?.map((img) => (
        images.push({
            description: `${img.Beschreibung}`,
            thumbnail: `${process.env.REACT_APP_IMG_PATH}/${img.Bildname}_low.jpg`,
            original: `${process.env.REACT_APP_IMG_PATH}/${img.Bildname}.jpg`,
        })
    ))

    return (
        <div className="images images_single_left col-xl-8 col-md-6 col-sm-12 order-md-1 ">
            <div className="title-single">
                <div className="title">
                    <h1>{trip.Kurztitel}</h1>
                </div>
            </div>

            <div className="flexslider">
                <ImageGallery lazyLoad={true} thumbnailPosition="bottom"
                              items={images.length === 0 ? placeholderImages : images}/>
            </div>

            <div className="clear"></div>

            <div className="single-tour-tabs wc-tabs-wrapper">
                <ul className="nav nav-tabs responsive tabs wc-tabs" role="tablist">
                    <li className="nav-item">
                        <a id="tab-tripPreviewTab" href="#pane-tripPreviewTab" className="nav-link active"
                           data-bs-toggle="tab"
                           role="tab">Überblick</a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-tripProgrammTab" href="#pane-tripProgrammTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Reiseprogramm</a>
                    </li>

                    {trip.HotelBeschreibung !== null && trip.HotelBeschreibung !== "" &&
                        <li className="nav-item">
                            <a id="tab-tripHotelTab" href="#pane-tripHotelTab" className="nav-link"
                               data-bs-toggle="tab"
                               role="tab">So wohnen Sie</a>
                        </li>
                    }

                    <li className="nav-item">
                        <a id="tab-tripServicesTab" href="#pane-tripServicesTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Leistungen</a>
                    </li>

                    {seeings.length > 0 &&
                        <li className="nav-item">
                            <a id="tab-tripSeeingsTab" href="#pane-tripSeeingsTab" className="nav-link"
                               data-bs-toggle="tab"
                               role="tab">Sehenswertes</a>
                        </li>
                    }
                    <li className="nav-item">
                        <a id="tab-tripInfoTab" href="#pane-tripInfoTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Wichtige Infos</a>
                    </li>
                    {references.length > 0 &&
                        <li className="nav-item">
                            <a id="tab-tripReferencesTab" href="#pane-tripReferencesTab" className="nav-link"
                               data-bs-toggle="tab"
                               role="tab">Referenzen</a>
                        </li>
                    }
                    <li className="nav-item">
                        <a id="tab-tripDatesTab" href="#pane-tripDatesTab" className="nav-link"
                           data-bs-toggle="tab"
                           role="tab">Termine und Preise</a>
                    </li>
                </ul>

                <div id="content" className="tab-content" role="tablist">
                    <DraftTripPreviewTab trip={trip}></DraftTripPreviewTab>

                    <DraftTripProgrammTab trip={trip}></DraftTripProgrammTab>

                    <DraftTripHotelTab trip={trip}></DraftTripHotelTab>

                    <DraftTripServicesTab
                        trip={trip}
                        includedServices={includedServices}
                        excludedServices={excludedServices}
                        localServices={localServices}
                        notIncludedServices={notIncludedServices}></DraftTripServicesTab>

                    <DraftTripSeeingsTab trip={trip} seeings={seeings}></DraftTripSeeingsTab>

                    <DraftTripInfoTab trip={trip}></DraftTripInfoTab>

                    <DraftTripReferencesTab trip={trip} references={references}></DraftTripReferencesTab>

                    <DraftTripDatesTab
                        trip={trip}
                        appointments={appointments}
                        loading={isAppointmentsLoading}
                        accommodations={accommodations}></DraftTripDatesTab>
                </div>
            </div>
        </div>
    )
}

export default DraftTripLeft;