import * as React from "react";
import {useEffect, useState} from "react";
import HeaderSubPage from "../HeaderSubPage";
import {useLocation, useSearchParams} from "react-router-dom";
import {fetchTransactionsDetails} from "../../utils/utils";

export default function Booked() {
    const [searchParams] = useSearchParams();
    const transactionNo = searchParams.get("tNo");
    const location = useLocation();
    const bookingDetails = location.state;

    const [transactionDetails, setTransactionDetail] = useState([]);

    useEffect(() => {
        fetchTransactionsDetails(transactionNo).then((data) => {
            setTransactionDetail(data);
        })
    }, []);
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Buchung Abgeschlossen'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <h1>Vielen Dank für Ihre Buchung</h1>
                                <p>
                                    Ihre Buchungsnummer lautet: <strong>{bookingDetails?.bookingNo}</strong>
                                </p>
                                <p>
                                    Ihre Kunden-Nummer lautet: <strong>{bookingDetails?.customerNo}</strong>
                                </p>
                                <p>
                                    Die Buchungsbestätigung senden wir an folgende
                                    E-Mail: <strong>{bookingDetails?.customerMail}</strong>
                                </p>
                                <p>Sie haben für {bookingDetails?.bookedParticipants.length} Person(en) gebucht.</p>
                                <ul>
                                    {bookingDetails?.bookedParticipants.map((participant) => (
                                        <li>{participant.gender} {participant.firstname} {participant.lastname}</li>
                                    ))}
                                </ul>
                                <p className="text-left">
                                    <h4>Ihre Reise:</h4>
                                    <br/>
                                    {transactionDetails.Kurztitel}
                                    <br/>
                                    <strong>{transactionDetails.DAUER}-tägige Reise
                                        vom {transactionDetails.appointment}
                                    </strong>
                                    {transactionDetails.TerminPlus}
                                    {transactionDetails.goPoint}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
