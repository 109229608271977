import * as React from "react";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import axiosConfig from "./utils/axiosConfig";
import HeaderSubPage from "./partial/HeaderSubPage";

export default function NewsletterUnsubscribe() {
    const [searchParams] = useSearchParams();
    const newsletterId = searchParams.get("id");

    const doUnsubscribe = async () => {
        try {
            const response = await axiosConfig.get(`/newsletter/${newsletterId}/unsubscription`);
            return response.data
        } catch (error) {
            //console.log(error);
            throw error
        }
    }

    useEffect(() => {
        doUnsubscribe()
    }, []);
    return (
        <div>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Newsletter Abmeldung'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <h1>
                                    Newsletter Abmeldung
                                </h1>
                                <p>
                                    Wir bedauern sehr, dass Sie sich von unserem Newsletter abgemeldet haben.
                                    Wunschgemäß
                                    haben wir Sie natürlich aus der Empfängerliste ausgetragen.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
