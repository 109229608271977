import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import {
    fetchExcludedServices,
    fetchIncludedServices,
    fetchLocalServices,
    fetchNotIncludedServices,
    fetchTrip,
    fetchTripAccommodations,
    fetchTripAppointment,
    fetchTripReferences,
    fetchTripSeeings
} from "../utils/utils";
import Loading from "../partial/Loading";
import DraftTripRight from "./DraftTripRight";
import DraftTripLeft from "./DraftTripLeft";

const DraftTrip = ({partner}) => {

    const navigate = useNavigate();

    const params = useParams();
    const [searchParams] = useSearchParams();
    const conId = searchParams.get("conId");
    const tripId = params["tId"];

    const [trip, setTrip] = useState([]);
    const [includedServices, setIncludedServices] = useState([]);
    const [excludedServices, setExcludedServices] = useState([]);
    const [localServices, setLocalServices] = useState([]);
    const [notIncludedServices, setNotIncludedServices] = useState([]);
    const [seeings, setSeeings] = useState([]);
    const [references, setReferences] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [accommodations, setAccommodations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);


    // Call fetchData on component mount
    useEffect(() => {
        fetchTrip(tripId, false).then((result) => {
            setTrip(result);
            setIsLoading(false)
            document.title = `${result.Reiseart} - ${result.Destination} - ${result.Kurztitel}`;
        }).catch((error) => {
            //if (error.response.status === 404) {
            navigate("/notfound");
            //}
        });
        fetchIncludedServices(tripId).then((result) => {
            setIncludedServices(result);
        });
        fetchExcludedServices(tripId).then((result) => {
            setExcludedServices(result);
        });
        fetchLocalServices(tripId).then((result) => {
            setLocalServices(result);
        });
        fetchNotIncludedServices(tripId).then((result) => {
            setNotIncludedServices(result);
        });
        fetchTripSeeings(tripId).then((result) => {
            setSeeings(result);
        });
        fetchTripReferences(tripId).then((result) => {
            setReferences(result);
        });
        fetchTripAppointment(tripId, conId).then((result) => {
            const groupByAirport = Object.groupBy(result, product => {
                return product.Abflughafen;
            });
            setAppointments(groupByAirport);
            setIsAppointmentsLoading(false)
        });
        fetchTripAccommodations(tripId).then((result) => {
            setAccommodations(result);
        });
    }, [partner]);
    return (
        <>
            <div className="single-product travel_tour-page travel_tour">
                <div className="site wrapper-content">
                    <div className="top_site_main" style={{backgroundColor: '#fff'}}>
                    </div>
                    <section className="content-area single-woo-tour">
                        <div className="container-fluid">
                            <div className="tb_single_tour product">
                                <div className="top_content_single row">
                                    <Loading isLoading={isLoading} showText={false}></Loading>
                                    <DraftTripRight trip={trip} tripId={tripId}></DraftTripRight>
                                    <DraftTripLeft
                                        isLoading={isLoading}
                                        tripId={tripId}
                                        trip={trip}
                                        references={references}
                                        seeings={seeings}
                                        appointments={appointments}
                                        isAppointmentsLoading={isAppointmentsLoading}
                                        accommodations={accommodations}
                                        includedServices={includedServices}
                                        excludedServices={excludedServices}
                                        localServices={localServices}
                                        notIncludedServices={notIncludedServices}
                                    ></DraftTripLeft>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default DraftTrip;