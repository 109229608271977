import React, {useEffect, useState} from 'react';
import Axios from "axios";
import {BrowserView, MobileView} from "react-device-detect";

const axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const Klima = ({tId}) => {
    const [klima, setKlima] = useState([]);
    const fetchKlima = async () => {
        try {
            const response = await axiosInstance.get(`/trips/${tId}/klima`);
            //console.log(response.data)
            setKlima(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Call fetchData on component mount
    useEffect(() => {
        fetchKlima()
    }, []);

    return (
        <div className="">
            <p><br/><strong>Klimatabelle {klima.Destination}: </strong></p>
            <BrowserView>
                <table className="table table-responsive table-striped">
                    <tbody>
                    <tr>
                        <th>
                            Monat:
                        </th>
                        <th>
                            1
                        </th>
                        <th>
                            2
                        </th>
                        <th>
                            3
                        </th>
                        <th>
                            4
                        </th>
                        <th>
                            5
                        </th>
                        <th>
                            6
                        </th>
                        <th>
                            7
                        </th>
                        <th>
                            8
                        </th>
                        <th>
                            9
                        </th>
                        <th>
                            10
                        </th>
                        <th>
                            11
                        </th>
                        <th>
                            12
                        </th>
                    </tr>
                    <tr>
                        <th>
                            Temp. &deg;C max.
                        </th>
                        <td>
                            {klima.Cmax01}
                        </td>
                        <td>
                            {klima.Cmax02}
                        </td>
                        <td>
                            {klima.Cmax03}
                        </td>
                        <td>
                            {klima.Cmax04}
                        </td>
                        <td>
                            {klima.Cmax05}
                        </td>
                        <td>
                            {klima.Cmax06}
                        </td>
                        <td>
                            {klima.Cmax07}
                        </td>
                        <td>
                            {klima.Cmax08}
                        </td>
                        <td>
                            {klima.Cmax09}
                        </td>
                        <td>
                            {klima.Cmax10}
                        </td>
                        <td>
                            {klima.Cmax11}
                        </td>
                        <td>
                            {klima.Cmax12}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Temp. &deg;C min.
                        </th>
                        <td>
                            {klima.Cmin01}
                        </td>
                        <td>
                            {klima.Cmin02}
                        </td>
                        <td>
                            {klima.Cmin03}
                        </td>
                        <td>
                            {klima.Cmin04}
                        </td>
                        <td>
                            {klima.Cmin05}
                        </td>
                        <td>
                            {klima.Cmin06}
                        </td>
                        <td>
                            {klima.Cmin07}
                        </td>
                        <td>
                            {klima.Cmin08}
                        </td>
                        <td>
                            {klima.Cmin09}
                        </td>
                        <td>
                            {klima.Cmin10}
                        </td>
                        <td>
                            {klima.Cmin11}
                        </td>
                        <td>
                            {klima.Cmin12}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Wassertemp. &deg;C
                        </th>
                        <td>
                            {klima.Wasser01}
                        </td>
                        <td>
                            {klima.Wasser02}
                        </td>
                        <td>
                            {klima.Wasser03}
                        </td>
                        <td>
                            {klima.Wasser04}
                        </td>
                        <td>
                            {klima.Wasser05}
                        </td>
                        <td>
                            {klima.Wasser06}
                        </td>
                        <td>
                            {klima.Wasser07}
                        </td>
                        <td>
                            {klima.Wasser08}
                        </td>
                        <td>
                            {klima.Wasser09}
                        </td>
                        <td>
                            {klima.Wasser10}
                        </td>
                        <td>
                            {klima.Wasser11}
                        </td>
                        <td>
                            {klima.Wasser12}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Sonnenstd. pro Tag
                        </th>
                        <td>
                            {klima.Sonne01}
                        </td>
                        <td>
                            {klima.Sonne02}
                        </td>
                        <td>
                            {klima.Sonne03}
                        </td>
                        <td>
                            {klima.Sonne04}
                        </td>
                        <td>
                            {klima.Sonne05}
                        </td>
                        <td>
                            {klima.Sonne06}
                        </td>
                        <td>
                            {klima.Sonne07}
                        </td>
                        <td>
                            {klima.Sonne08}
                        </td>
                        <td>
                            {klima.Sonne09}
                        </td>
                        <td>
                            {klima.Sonne10}
                        </td>
                        <td>
                            {klima.Sonne11}
                        </td>
                        <td>
                            {klima.Sonne12}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </BrowserView>
            <MobileView>
                <table className="table table-responsive table-striped">
                    <tbody>
                    <tr>
                        <th>
                            Monat:
                        </th>
                        <th>
                            Temp. &deg;C max.
                        </th>
                        <th>
                            Temp. &deg;C min.
                        </th>
                        <th>
                            W. Temp. &deg;C
                        </th>
                        <th>
                            Sonnenstd. pro Tag
                        </th>
                    </tr>
                    <tr>
                        <th>
                            1
                        </th>
                        <td>
                            {klima.Cmax01}
                        </td>
                        <td>
                            {klima.Cmin01}
                        </td>
                        <td>
                            {klima.Wasser01}
                        </td>
                        <td>
                            {klima.Sonne01}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            2
                        </th>
                        <td>
                            {klima.Cmax02}
                        </td>
                        <td>
                            {klima.Cmin02}
                        </td>
                        <td>
                            {klima.Wasser02}
                        </td>
                        <td>
                            {klima.Sonne02}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            4
                        </th>
                        <td>
                            {klima.Cmax04}
                        </td>
                        <td>
                            {klima.Cmin04}
                        </td>
                        <td>
                            {klima.Wasser04}
                        </td>
                        <td>
                            {klima.Sonne04}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            5
                        </th>
                        <td>
                            {klima.Cmax05}
                        </td>
                        <td>
                            {klima.Cmin05}
                        </td>
                        <td>
                            {klima.Wasser05}
                        </td>
                        <td>
                            {klima.Sonne05}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            6
                        </th>
                        <td>
                            {klima.Cmax06}
                        </td>
                        <td>
                            {klima.Cmin06}
                        </td>
                        <td>
                            {klima.Wasser06}
                        </td>
                        <td>
                            {klima.Sonne06}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            7
                        </th>
                        <td>
                            {klima.Cmax07}
                        </td>
                        <td>
                            {klima.Cmin07}
                        </td>
                        <td>
                            {klima.Wasser07}
                        </td>
                        <td>
                            {klima.Sonne07}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            8
                        </th>
                        <td>
                            {klima.Cmax08}
                        </td>
                        <td>
                            {klima.Cmin08}
                        </td>
                        <td>
                            {klima.Wasser08}
                        </td>
                        <td>
                            {klima.Sonne08}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            9
                        </th>
                        <td>
                            {klima.Cmax09}
                        </td>
                        <td>
                            {klima.Cmin09}
                        </td>
                        <td>
                            {klima.Wasser09}
                        </td>
                        <td>
                            {klima.Sonne09}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            10
                        </th>
                        <td>
                            {klima.Cmax10}
                        </td>
                        <td>
                            {klima.Cmin10}
                        </td>
                        <td>
                            {klima.Wasser10}
                        </td>
                        <td>
                            {klima.Sonne10}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            11
                        </th>
                        <td>
                            {klima.Cmax11}
                        </td>
                        <td>
                            {klima.Cmin11}
                        </td>
                        <td>
                            {klima.Wasser11}
                        </td>
                        <td>
                            {klima.Sonne11}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            12
                        </th>
                        <td>
                            {klima.Cmax12}
                        </td>
                        <td>
                            {klima.Cmin12}
                        </td>
                        <td>
                            {klima.Wasser12}
                        </td>
                        <td>
                            {klima.Sonne12}
                        </td>
                    </tr>

                    </tbody>
                </table>
            </MobileView>
        </div>
    )
}

export default Klima;