import React from 'react';

const Loading = ({isLoading, showText = true}) => {
    return (
        isLoading &&
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="row">
                <div className="spinner-border text-secondary"
                     style={{width: "5rem", height: "5rem"}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="row">
                {showText &&
                    <strong>Bitte warten Daten werden geladen.</strong>
                }
            </div>
        </div>
    )
}

export default Loading;