import {reviews} from "../utils/utils";
import React from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ReviewsCarousel = () => {

    const options = {
        autoplay:true,
        autoplayTimeout: 2500,
        autoplayHoverPause:true,
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    return (
        <div className="col-sm-4">
            <div className="shortcode_title title-center title-decoration-bottom-center">
                <h2 className="title_primary">Bewertungen</h2>
                <span className="line_after_title"></span>
            </div>
            <div className="shortcode-tour-reviews wrapper-tours-slider">
                {/* <div className="tours-type-slider" data-autoplay="true" data-dots="true"
                     data-nav="false"
                     data-responsive='{"0":{"items":1}, "480":{"items":1}, "768":{"items":1}, "992":{"items":1}, "1200":{"items":1}}'>*/}
                <OwlCarousel className="tours-type-slider  owl-theme" {...options}>
                    {reviews.map((post, index) =>
                        <div className="tour-reviews-item" key={index}>
                            <div className="reviews-item-info">
                                <div className="avatar">
                                    <i className="fa fa-user-circle"></i>
                                </div>
                                <div className="reviews-item-info-name">Anonymer Nutzer</div>
                                <div className="reviews-item-rating">
                                    <i className="fa fa-star"></i><i className="fa fa-star"></i><i
                                    className="fa fa-star"></i><i className="fa fa-star"></i><i
                                    className="fa fa-star"></i>
                                </div>
                            </div>
                            <div className="reviews-item-content">
                                <h3 className="reviews-item-title">
                                    <a href="#">{post.Reise}</a>
                                </h3>
                                <div className="reviews-item-description">{post.Beschreibung}
                                </div>
                            </div>
                        </div>
                    )}
                </OwlCarousel>
            </div>
        </div>)
}

export default ReviewsCarousel;