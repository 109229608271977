import React from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const Image = process.env.PUBLIC_URL + "/images/headers/rom_colloseum.png";
//const Image2 = process.env.PUBLIC_URL + "/images/headers/reise-titelbild-min.png";


const HeaderSubPage = ({title, subtitle, bgImage}) => {
    const bg = bgImage ? bgImage : Image
    return (
        <LazyLoadComponent>
        <div className="top_site_main" style={{backgroundImage: 'url(' + bg + ')'}}>
            <div className="banner-wrapper-destination container article_heading text-center">
                <h1 className="heading_primary">{title}</h1>
                <div className="breadcrumbs-wrapper">
                    {/*<ul className="phys-breadcrumb">
                        {subtitle && <li><a href="/" className="home">Home</a></li>}
                        {subtitle && <li><a href="#" title="Tours">{subtitle}</a></li>}
                    </ul>*/}
                </div>
            </div>
        </div>
        </LazyLoadComponent>
    )
}

export default HeaderSubPage