import {createContext, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import axiosConfig from "../components/utils/axiosConfig";
import {notify} from "../components/utils/utils";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || "");
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")) || "");
    const navigate = useNavigate();


    const loginAction = async (data) => {
        try {
            const res = await axiosConfig.post(`/login`, data);
            if (res.data) {
                //console.log(res.data.user)
                setUser(res.data.user);
                setToken(res.data.authorization.token);
                localStorage.setItem("user", JSON.stringify(res.data.user));
                localStorage.setItem("token", JSON.stringify(res.data.authorization.token));
                navigate("/dashboard");
                window.location.reload();
            } else {
                notify("zugriff verweigert bitte wenden sie sich an den administrator")
                navigate("/login");
                //window.location.reload();
            }
        } catch (err) {
            notify("zugriff verweigert bitte wenden sie sich an den administrator")
            navigate("/login");
            //window.location.reload();
            //console.error(err);
        }
    };

    const logOut = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
        window.location.reload();
    };

    return (
        <AuthContext.Provider value={{token, user, loginAction, logOut}}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};