import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import {fetchContingentByAgency, localizeDate} from "../utils/utils";
import Loading from "../partial/Loading"; // Optional Theme applied to the Data Grid
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import "datatables.net-dt/css/dataTables.dataTables.css";
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.html5';
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import languageDe from "datatables.net-plugins/i18n/de-DE.mjs";

DataTable.use(DT);
DT.Buttons.jszip(jszip);
DT.Buttons.pdfMake(pdfmake);

const Contingents = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [contingents, setContingents] = useState([]);
    const [columns, setColumns] = useState([
        {
            data: 'Abflugtermin', render: function (data) {
                return localizeDate(data, false);
            }
        },
        {data: 'Abflughafen'},
        {data: 'Destination'},
        {
            data: 'Reise_ID', render: function (data, type, row, meta) {
                return `<a target="_blank" href="/reise/${row.Reisetitel}?id=${row.Reise_ID}">${row.Reisetitel}</a>`;
            }
        },
        {
            data: 'Zielbearbeiter', render: function (data, type, row, meta) {
                let html = "</p>";
                if (data && data.name) {
                    html += `${data.name} `;
                }

                if (data && data.email) {
                    html += `<a href="mailto:${data.email}">${data.email}</a>`
                }

                html += "</p>";

                return html;
            }
        },
        {
            data: 'VORGANG_ID', render: function (data, type, row, meta) {
                if (row.rest <= 0) {
                    return "Aktuell ausgebucht";
                } else {
                    return `<a target="_blank" href="/reisebuchung/${row.Reisetitel}?id=${row.Reise_ID}&tNo=${row.VORGANGNR}">Jetzt buchen</a>`;
                }
            }
        },
    ]);


    useEffect(() => {
        setIsLoading(true);
        fetchContingentByAgency(auth.user.Kundennummer, auth.token).then((response) => {
            setContingents(response)
            setIsLoading(false)
        });
    }, [])

    return (
        <div className="tab-pane fade " id="contingent" role="tabpanel"
             aria-labelledby="contingent-tab">

            <div className="mb-5">
                <h1>Kontingente</h1>
                <p>
                    <i className="fa fa-info-circle"></i> Hier können Sie Ihre Reisegäste auf Ihre Kontingente
                    einbuchen. Klicken Sie beim gewünschten
                    Kontingent auf &quot;<b>Buchen</b>&quot;
                </p>
            </div>

            <Loading isLoading={isLoading}/>

            <DataTable
                options={
                    {
                        language: languageDe,
                        responsive: true,
                        select: true,
                        pageLength: -1,
                        lengthMenu: [[50, 100, 500, 1000, -1], [50, 100, 500, 1000, "All"]],
                        layout: {
                            bottomStart: 'buttons',
                        },
                    }
                }
                data={contingents} columns={columns} className="display">
                <thead>
                <tr>
                    <th>Abreise</th>
                    <th>Abflughafen</th>
                    <th>Reiseziel</th>
                    <th>Reise</th>
                    <th>Ansprechpartner</th>
                    <th>Buchen</th>
                </tr>
                </thead>
            </DataTable>

        </div>
    )
}

export default Contingents;