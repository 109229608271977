import React, {useEffect, useState} from 'react';
import {allTripDestinations, allTripTypes, formatCurrency} from "../utils/utils";
import {useSearchParams} from "react-router-dom";
import bootstrap from 'bootstrap/dist/js/bootstrap.js';

export const FavModal = () => {

    const [storageItem, setStorageItem] = useState(JSON.parse(localStorage.getItem("favourites") || "[]"));

    useEffect(() => {
        const handleStorage = () => {
            setStorageItem(JSON.parse(localStorage.getItem("favourites") || "[]"))
        }

        window.addEventListener('storage', () => {
            handleStorage()
        });
    }, [])


    const handleToggleFavourite = (trip) => {
        const newStorageItem = JSON.parse(localStorage.getItem("favourites") || "[]").filter((savedId) => savedId.ID !== trip.ID);
        setStorageItem(newStorageItem);
        localStorage.setItem("favourites", JSON.stringify(newStorageItem));

        const event = new CustomEvent('storage');
        window.dispatchEvent(event);
    };

    return (
        <>
            <div className="modal fade" id="favModal" tabIndex="-1" aria-labelledby="favModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="favModalLabel">Gemerkte Reisen</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-responsive table-borderless borderless table-striped"
                                   border="0">
                                <tbody>
                                {storageItem.length > 0 ? (
                                    storageItem?.map((trip, index) => (
                                        <tr key={index}>
                                            <td>
                                                <button className="button-icon"
                                                        onClick={() => handleToggleFavourite(trip)}><i
                                                    className="fa fa-trash"></i></button>
                                            </td>
                                            <td>
                                                <a
                                                    href={`${process.env.PUBLIC_URL}/reise/` + encodeURIComponent(trip.Kurztitel) + '?id=' + trip.ID}>{trip.Kurztitel}
                                                </a>
                                            </td>
                                            <td>{trip.termin}</td>
                                            <td>
                                                ab {trip.Abflughafen}
                                            </td>
                                            <td>
                                                ab {formatCurrency(trip.ABPREIS)}
                                            </td>
                                        </tr>
                                    ))) : (
                                    <h3 className="text-center">Keinen Reise gemerkt</h3>
                                )
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SearchModal = () => {
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("s") ?? "");
    const [type, setType] = useState(searchParams.get("reiseart") ?? "");
    const [dest, setDest] = useState(searchParams.get("reiseziel") ?? "");

    return (
        <>
            <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="searchModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="searchModalLabel">Reisefinder</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="row" method="get" action={`${process.env.PUBLIC_URL}/reisefinder/`}>
                                <h2 className="text-center mb-3">Finden Sie Ihre Traumreise jetzt!</h2>
                                <div className="row mb-3">
                                    <div className="col-sm ">
                                        {search &&
                                            <span className="badge rounded-pill p-2">
                                                    {search}
                                                <button className="button-icon" onClick={() => setSearch("")}><i
                                                    className="fa fa-close ps-5"></i></button>
                                                </span>
                                        }
                                        {type &&
                                            <span className="badge rounded-pill p-2">
                                                    {type}
                                                <button className="button-icon" onClick={() => setType("")}><i
                                                    className="fa fa-close ps-5"></i></button>
                                                </span>

                                        }
                                        {dest &&
                                            <span className="badge rounded-pill p-2">
                                                    {dest}
                                                <button className="button-icon" onClick={() => setDest("")}><i
                                                    className="fa fa-close ps-5"></i></button>
                                                </span>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><i
                                            className="fa fa-search"></i></span>
                                        <input type="text" className="form-control" id="s" name="s"
                                               placeholder="Stichwortsuche..."
                                               value={search}
                                               onChange={(e) => setSearch(e.target.value)}/>
                                    </div>


                                </div>
                                <div className="mb-3">
                                    <input type="hidden" name="tour_search" value="1"/>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><i
                                            className="fa fa-plane"></i></span>
                                        <select className="form-select" aria-label="Default select reiseart"
                                                name="reiseart"
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}>
                                            <option value="">Reiseart</option>
                                            {allTripTypes.map((post, i) => (
                                                <option value={post.title} key={i}>{post.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><i
                                            className="fa fa-suitcase"></i></span>
                                        <select className="form-select" aria-label="Default select reiseart"
                                                name="reiseziel"
                                                value={dest}
                                                onChange={(e) => setDest(e.target.value)}>
                                            <option value=""> Reiseziel</option>
                                            {allTripDestinations.map((post, x) => (
                                                <option value={post.title}
                                                        key={x}>{post.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Suchen</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export const BannerModal = () => {
    const tripId = 1706;
    const [hideBanner] = useState(JSON.parse(localStorage.getItem(`hideBanner-${tripId}`) || false));
    const pathname = window.location.pathname;

    const showModal = () => {
        const element = document.getElementById(`bannerModal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.show()
    }

    const closeModal = () => {
        localStorage.setItem(`hideBanner-${tripId}`, true);
        const element = document.getElementById(`bannerModal`);
        const modal = bootstrap.Modal.getOrCreateInstance(element)
        modal.hide()
    }

    const goToTrip = () => {
        const tripUrl = `${process.env.PUBLIC_URL}/reise/Kairo%20–%20Zur%20Eröffnung%20des%20Grand%20Museums%20mit%20einer%20exklusiven%20Kleingruppe?id=${tripId}`;
        closeModal();
        window.open(tripUrl, "_blank");
    }

    useEffect(() => {
        if (!hideBanner && pathname === "/") {
            setTimeout(() => {
                showModal()
            }, 1000)
        }
    }, [])

    return (
        <>
            <div className="modal fade" id="bannerModal" tabIndex="-1" aria-labelledby="bannerModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title " id="bannerModalLabel">
                                Exklusive Kleingruppe: Zur Eröffnung des Grandmuseums in Kairo
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img src={process.env.PUBLIC_URL + '/images/banner/3.jpg'}
                                             alt=""
                                             className="aligncenter"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1>
                                            Erleben Sie die faszinierende Welt der Pharaonen im Grand Egyptian Museum
                                            (GEM), nahe den Pyramiden von Gizeh.
                                        </h1>
                                        <p>
                                            Nach über 12 Jahren Bauzeit öffnet dieses architektonische Meisterwerk seine
                                            Türen und bietet eine unvergleichliche Reise durch Ägyptens Geschichte.
                                            Mit über 100.000 Artefakten auf 480.000 qm ist das GEM mehr als ein Museum –
                                            es ist ein kulturelles Epizentrum, das die reiche Geschichte Ägyptens
                                            würdigt.
                                            Besuchen Sie uns zur Eröffnung in einer exklusiven Kleingruppe und entdecken
                                            Sie neben dem GEM auch das Ägyptische Museum, das Museum für Zivilisationen
                                            und die Pyramiden von Gizeh. Diese Reise ist ein Muss für Kultur- und
                                            Museumsliebhaber!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal"
                                    onClick={() => closeModal()}>Nicht mehr
                                anzeigen
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => goToTrip()}><span className="fs-5">Reise ansehen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
